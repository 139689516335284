import React from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./form.module.scss";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";

function Form() {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const person = useRef();
  const [openScaleX, setOpenScaleX] = useState(false);
  const [inf, setInf] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clickedIndex, setClickedBudgetLine] = useState(4);
  const [isFullWebServiceSelected, setIsFullWebServiceSelected] = useState(false);
  const [isLandingPageActive, setIsLandingPageActive] = useState(false);

  const [form, setForm] = useState({
    email: "",
    fullName: "",
    social_media: "",
    phone: "",
    company: "",
    description: "",
    titleProject: "",
    country: "",
    code_country: "",
    projectType: [],
    budgetLine: "",
  });

  const [list, setList] = useState([
    {
      id: 0,
      budget: "$ 1000 +",
      active: false,
    },
    {
      id: 1,
      budget: "$ 5000 +",
      active: false,
    },
    {
      id: 2,
      budget: "$ 10000 +",
      active: false,
    },
    {
      id: 3,
      budget: "$ 25000 +",
      active: false,
    },
    {
      id: 4,
      budget: "$ 50000 +",
      active: true,
    },
    {
      id: 5,
      budget: "$ 75000 +",
      active: false,
    },
    {
      id: 6,
      budget: "$ 100000 +",
      active: false,
    },
    {
      id: 7,
      budget: "$ 250000 +",
      active: false,
    },
  ]);

  const [projectLine, setProjectLine] = useState([
    {
      id: 0,
      project: "Full Web Service",
      active: false,
    },
    {
      id: 1,
      project: "Design - UI/UX",
      active: false,
    },
    {
      id: 2,
      project: "Frontend",
      active: false,
    },
    {
      id: 3,
      project: "Backend",
      active: false,
    },
    {
      id: 4,
      project: "DevOps",
      active: false,
    },
  ]);

  const [formErrors, setFormErrors] = useState([]);

  const validate = (name, value) => {
    switch (name) {
      case "fullName":
        if (!value) {
          return "Name is required";
        }
        break;
      case "email":
        const emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(String(value).toLowerCase())) {
          return "Email address is invalid";
        }
        break;
      case "phone":
        try {
          const number = phoneUtil.parseAndKeepRawInput(
            value,
            form.code_country
          );
          if (!phoneUtil.isValidNumber(number)) {
            return "Phone number is invalid";
          }
        } catch (e) {
          return "Phone number is invalid";
        }
        break;
      case "description":
        if (!value) {
          return "Description is required";
        }
        break;
      default:
        return "";
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    setFormErrors({ ...formErrors, [name]: validate(name, value) });
  };

  const isDevelopmentProjectSelected = () => {
    const developmentProjects = ["Frontend", "Backend", "DevOps"];
    return developmentProjects.some((project) =>
      form.projectType.includes(project)
    );
  };

  const allDevelopmentProjectsSelected = () => {
    const developmentProjects = ["Frontend", "Backend", "DevOps"];
    return developmentProjects.every((project) =>
      form.projectType.includes(project)
    );
  };
  const handleLandingPageClick = () => {
    const landingPageProjectType = "Landing Page";
    const landingPageBudgetLine = "$ 5000 +";

    setIsLandingPageActive(!isLandingPageActive);

    if (!isLandingPageActive) {
      setForm({
        ...form,
        projectType: [landingPageProjectType],
        budgetLine: landingPageBudgetLine.replace(/\s+/g, ""),
      });
      setProjectLine(
        projectLine.map((item) => ({
          ...item,
          active: item.project === landingPageProjectType,
        }))
      );
      setList(
        list.map((item) => ({
          ...item,
          active: item.budget === landingPageBudgetLine,
        }))
      );
    } else {
      setForm({
        ...form,
        projectType: [],
        budgetLine: "",
      });
      setProjectLine(projectLine.map((item) => ({ ...item, active: false })));
      setList(list.map((item) => ({ ...item, active: false })));
    }
  };

  function handleSendEmail(e) {
    e.stopPropagation();
    e.preventDefault();
    const phoneError = validate("phone", form.phone);
    if (phoneError) {
      toast.error(phoneError);
      return;
    }

    console.log("form", form);
    if (
      form.fullName === "" ||
      form.email === "" ||
      form.description === "" ||
      form.phone === ""
    ) {
      Object.entries(form).map((item) =>
        item[1] === "" ? toast.error(`${item[0]} is required`) : ""
      );
      return;
    }

    emailjs
      .sendForm(
        "service_y2i73bi",
        "template_ih1yjmn",
        person.current,
        "J9vw8coxBzKNDlDiN"
      )
      .then((res) => {
        console.log(res);
        toast.success("Message successfully sending", {
          position: "top-center",
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("There are happen error", {
          position: "top-center",
          autoClose: 3000,
        });
      });

    setForm({
      fullName: "",
      email: "",
      social_media: "",
      description: "",
      company: "",
      phone: "",
      titleProject: "",
      projectType: [],
      budgetLine: "",
    });
    console.log(form);
  }

  const handleProjectTypeSelection = (selectedProject, index) => {
    if (selectedProject === "Full Web Service") {
      setForm({
        ...form,
        projectType: isFullWebServiceSelected ? [] : ["Full Web Service"],
      });
      setIsFullWebServiceSelected(!isFullWebServiceSelected);
    } else if (!isFullWebServiceSelected) {
      setForm((prevForm) => {
        const isAlreadySelected =
          prevForm.projectType.includes(selectedProject);
        return {
          ...prevForm,
          projectType: isAlreadySelected
            ? prevForm.projectType.filter((type) => type !== selectedProject)
            : [...prevForm.projectType, selectedProject],
        };
      });
    }
    // Update projectLine active states
    handleClickProjectType(index, selectedProject);
  };

  const handleClickProjectType = (index, project) => {
    setProjectLine((prevList) =>
      prevList.map((item, i) => {
        if (project === "Full Web Service") {
          return {
            ...item,
            active: i === index ? !item.active : false,
          };
        } else if (!isFullWebServiceSelected) {
          return {
            ...item,
            active: i === index ? !item.active : item.active,
          };
        }
        return item;
      })
    );
  };

  const handleBudgetLineSelection = (budgetLine) => {
    console.log("budget selected", budgetLine);
    setForm({ ...form, budgetLine: budgetLine.replace(/\s+/g, "") });
  };
  const handleClickBudgetLine = (index) => {
    setClickedBudgetLine(index);
    setList((prevList) =>
      prevList.map((item, i) => ({
        ...item,
        active: i === index ? !item.active : false,
      }))
    );
  };

  return (
    <form ref={person} onSubmit={handleSendEmail} className={`${style.form} `}>
      <ToastContainer />
      <input
        type="hidden"
        name="projectType"
        value={form.projectType.join(", ")}
      />

      <input type="hidden" name="budgetLine" value={form.budgetLine} />

      <fildset className={style.fildset}>
        <input
          name="fullName"
          value={form.fullName}
          onChange={handleChange}
          placeholder="Full Name"
          className={style.input}
        />
        {formErrors.fullName && (
          <p className={style.error}>{formErrors.fullName}</p>
        )}
      </fildset>

      <fildset className={style.fildset}>
        <input
          name="email"
          value={form.email}
          onChange={handleChange}
          placeholder="Email"
          className={style.input}
        />
        {formErrors.email && <p className={style.error}>{formErrors.email}</p>}
      </fildset>

      <fildset className={`${style.fildset} `}>
        <PhoneInput
          name="phone"
          className={`${style.react_international_phone_input}`}
          defaultCountry="us"
          value={form.phone}
          onChange={(phone, { country }) => {
            setForm({
              ...form,
              phone: phone,
              country: country.name,
              code_country: "+" + country.dialCode,
            });
          }}
        />
        <input
          name={"country"}
          type={"text"}
          value={form.country}
          style={{ display: "none" }}
        />
        {formErrors.phone && <p className={style.error}>{formErrors.phone}</p>}
      </fildset>

      <fildset className={`${style.fildset} ${style.dropdown}`}>
        <div
          className={style.dropdown_fildset}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>Budget Line & Project Type</span>

          <div
            className={`${style.drop_down_fildset_ico_open} ${
              !isDropdownOpen ? "" : style.active
            }`}
          >
            <div className={`${style.drop_down_fildset_ico} `}></div>
            <img
              className={style.dropdown_btn_icon}
              alt="dropdown_icon"
              height={20}
              width={20}
              src="/assets/icons/arrow_icon.svg"
            />
          </div>
        </div>
        {isDropdownOpen && (
          <div
            className={`${style.dropdown_fildset_body} ${
              isDropdownOpen ? "open" : ""
            }`}
          >
            <div className={style.project_type}>
              <ul>
                {projectLine.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleProjectTypeSelection(`${item.project}`);
                      handleClickProjectType(index);
                    }}
                    className={`${style.information} ${
                      item.active ? style.active : ""
                    } ${
                      isFullWebServiceSelected &&
                      item.project !== "Full Web Service"
                        ? style.disabledOption
                        : ""
                    }`}
                  >
                    <span> {item.project}</span>
                    {item.id === 0 && (
                      <img
                        src="/assets/icons/information_icon.svg"
                        width={16}
                        height={16}
                        alt="checked_icon"
                        onMouseEnter={() => setInf(true)}
                        onMouseLeave={() => setInf(false)}
                      />
                    )}
                  </li>
                ))}
                <li
                  className={style.best_offer}
                  onClick={handleLandingPageClick}
                >
                  <div
                    className={`${style.best_offer_text} ${
                      isLandingPageActive
                        ? style.best_offer_mobile_selected
                        : ""
                    }`}
                  >
                    <span>Landing Pages</span>
                    <span>$ 5000 +</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.budget_line}>
              <ul>
                <ul>
                  {list.map((item, index) => (
                    <li
                      key={index}
                      className={`${style.information} ${
                        item.active ? style.active : ""
                      } ${
                        // Disable $1000 option if any of the development projects are selected
                        isDevelopmentProjectSelected() && index === 0
                          ? style.disabledOption
                          : ""
                      } ${
                        // Further disable $5000 option if all development projects are selected
                        allDevelopmentProjectsSelected() &&
                        [0, 1].includes(index)
                          ? style.disabledOption
                          : ""
                      } ${
                        // Disable $1000, $5000, and $10000 options if "Full Web Service" is selected
                        form.projectType.includes("Full Web Service") &&
                        [0, 1, 2].includes(index)
                          ? style.disabledOption
                          : ""
                      }`}
                      onClick={() => {
                        // Prevent interaction if the option is disabled
                        if (
                          (isDevelopmentProjectSelected() && index === 0) ||
                          (allDevelopmentProjectsSelected() &&
                            [0, 1].includes(index)) ||
                          (form.projectType.includes("Full Web Service") &&
                            [0, 1, 2].includes(index))
                        ) {
                          return;
                        }
                        handleBudgetLineSelection(`${item.budget}`);
                        handleClickBudgetLine(index);
                      }}
                    >
                      {item.active && (
                        <img
                          src="/assets/icons/checked_icon.svg"
                          width={16}
                          height={16}
                          alt="checked_icon"
                          className={style.list_item_success}
                        />
                      )}
                      <span>{item.budget}</span>
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
            <div
              className={`${style.best_offer_mobile}`}
              onClick={handleLandingPageClick}
            >
              <div
                className={`${style.best_offer_text} ${
                  isLandingPageActive ? style.best_offer_mobile_selected : ""
                }`}
              >
                <span>Landing Pages</span>
                <span>$ 5000 +</span>
              </div>
            </div>
          </div>
        )}
        {inf && (
          <div className={`${style.information_mostpopular} ${style.inf}`}>
            <span>Design, Development</span>
            <span>Deploy</span>
          </div>
        )}

        {openScaleX && (
          <div className={`${style.information_mostpopular} ${style.scalex}`}>
            <span>Most Popular</span>
          </div>
        )}
      </fildset>

      <fildset className={style.fildset}>
        <input
          name={"company"}
          value={form.company}
          onChange={handleChange}
          placeholder="Company"
          className={style.input}
        />
      </fildset>

      <fildset className={style.fildset}>
        <input
          name="social_media"
          onChange={handleChange}
          placeholder="Social Media Links"
          className={style.input}
        />
        {formErrors.social_media && (
          <p className={style.error}>{formErrors.social_media}</p>
        )}
      </fildset>

      <div className={style.fildset_body}>
        <h3 className={style.fildset_header}>Describe Your Project </h3>
        <div className={style.fildset_text_inputs}>
          <fildset className={` ${style.description_title}`}>
            <input
              name="titleProject"
              type={"text"}
              value={form.titleProject}
              onChange={handleChange}
              placeholder="Title"
              className={` ${style.input_title}`}
            />
          </fildset>

          <fildset className={` ${style.description_title}`}>
            <textarea
              name={"description"}
              value={form.description}
              onChange={handleChange}
              placeholder="Description"
              rows={3}
              className={`${style.input_description}`}
            />
            {formErrors.description && (
              <p className={style.error}>{formErrors.description}</p>
            )}
          </fildset>
        </div>
      </div>

      <div className={style.btn_liner_gradient}>
        <button type="submit" className={`${style.btn_form}`}>
          <div className={style.btn_text}>SEND MESSAGE</div>
        </button>
      </div>
    </form>
  );
}

export default Form;
